body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5fdff; 
  }
  .password-group {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #aaa;
    user-select: none; /* Prevent text selection */
  }
  
  .password-toggle.show {
    color: #005161; /* Change colour when password is visible */
  }
  
  .signup-container {
    max-width: 500px; 
    margin: 7rem auto;
    padding: 20px;
    background-color: white; 
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #005161; 
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-input-group {
    margin-bottom: 20px;
  }
  
  .signup-input {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border: 2px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out;
    outline-color: #005161;
  }
  
  .signup-input:focus {
    border-color: #005161; 
  }
  
  .signup-button {
    padding: 12px;
    background-color: #005161;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .signup-button:hover {
    background-color: #0c687a; 
  }
  
  .signup-error {
    padding: 10px;
    border-radius: 4px;
    background-color: #f8d7da; 
    color: #721c24;
    text-align: center;
  }
  
  .signup-success {
    padding: 10px;
    border-radius: 4px;
    background-color: #d4edda; 
    color: #155724;
    text-align: center;
  }
  
  
  .signup-footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .signup-footer a {
    color: #005161;
    text-decoration: none;
  }
  
  .signup-footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .signup-container {
      margin: 5rem auto;
    }
  }
  