.chatbot-detail {
  display: flex;
  
}

.chatbot-detail h1 {
  margin-bottom: 1rem;
}

.vertical-tabs-container {
  display: flex;
}

.vertical-tabs-container .MuiTabs-vertical {
  border-right: 1px solid #ccc;
}

.form-control {
  margin-bottom: 1rem;
}

