.social-media-actions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; 
}

.loading-gif {
    width: 200px;
    height: 200px;
    margin-top: 1rem; 
}

.facebook-login-btn {
    padding: 10px 20px; 
    background-color: #1877F2;
    border: none;
    border-radius: 8px; 
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem; /* Add some space between the image and the button */
}

.facebook-login-btn:hover {
    background-color: #165bc0; 
}

.managed-facebook-pages {
    text-align: centre;
    margin-top: 2rem; 
}

.facebook-page-info {
    list-style-type: none;
    margin: 10px 0;
    border-radius: 4px;
    border: 2px solid #ddd;
    background-color: white;
    padding: 10px; 
}

.facebook-page-info button {
    padding: 8px 12px;
    background-color: #005161;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.facebook-page-info button:hover {
    background-color: #00434f;
}

.connect-image-placeholder {
    flex: 1;
    text-align: centre;
}

.hero-image-connect {
    max-width: 10rem;
    height: auto;
    display: block;
    margin: 1rem;
    align-self: center;
}

@media only screen and (max-width: 768px) {
    .connect-first-screen {
      flex-direction: column;
    }
  
}
