.pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    margin-top: 3.5rem;
    overflow: hidden; /* Prevents horizontal overflow */
    box-sizing: border-box; /* Ensures padding is included in the element's total width and height */
}
.pricingCard-feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feature-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.check-icon {
    margin-right: 8px;
}

.feature-content .MuiIconButton-root {
    margin-left: auto;
    padding: 4px; /* Adjust padding if needed */
}

.pricingCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    max-width: 300px;
    transition: transform 0.3s;
    box-sizing: border-box; /* Ensures padding is included in the element's total width and height */
}

.pricingCard:hover {
    transform: translateY(-10px);
}

.pricingCard-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #343a40;
}

.pricingCard-price {
    font-size: 2em;
    margin: 10px 0;
    color: #189eb6;
}

.pricingCard-duration {
    font-size: 1em;
    margin-bottom: 20px;
    color: #6c757d;
}

.pricingCard-features {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-bottom: 20px;
    flex-grow: 1;
}

.pricingCard-features li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #495057;
}

.check-icon {
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 16px;
    color: green;
}

.subscribe-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #092F36  !important;
    color: white !important; 
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.subscribe-button:hover {
    background-color: #092F36 !important;
}
/* Add styles for the most popular label */
.most-popular-label {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color:#092F36 ; /* Choose a color that stands out */
    color: white;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 5px;
    transform: rotate(15deg);
}

/* Highlight the most popular plan */
.pricingCard.most-popular {
    border: 2px solid #092F36 ; /* Highlight border color */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff3e0; /* Slight background color to differentiate */
    position: relative; /* For positioning the label */
    z-index: 1;
}

/* Optional: Enhance the appearance of the most popular card */
.pricingCard.most-popular .pricingCard-title {
    color: #092F36 ;
}

.pricingCard.most-popular .subscribe-button {
    background-color: #092F36 ;
    color: white;
}

.pricingCard.most-popular .subscribe-button:hover {
    background-color: #092F36 ;
}
