@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
.chat-widget-preview-box {
    position: fixed;
    right: 2rem;
    top: 1rem; /* Adjust this to position it from the top if needed */
    max-width: 25rem; /* Set an explicit width */
    height: 70vh; /* Maintain the height */
}
.chatWidgetPreview {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25%;
    border: 1px solid #e0e0e0;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: inherit;
    z-index: 10000;
  }
  

  #chat-input-preview {
    font-size: 16px;
    border: none !important;
    outline: none !important;
    background-color: white;
    padding: 10px;
    font-family: inherit;
    resize: none;
    border-radius: 20px;
  }
  
#chat-widget {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 24rem;
    border: 1px solid #e0e0e0;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    z-index: 1000;
}
.refresh-icon-preview {
    position: absolute; /* Absolute positioning */
    right: 36px; /* Adjusted right position */
    top: 50%; /* Vertically centered */
    transform: translateY(-50%); /* Adjust vertical alignment */
    cursor: pointer;
    font-size: 1.2rem; /* Adjust size if necessary */
}
.circle-icon {
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 10001; /* Ensure it appears above other elements */
    color: white; /* Icon colour */
    
}

.feedbackIcons {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
  }
  .feedbackIcon {
    color: #999;
    font-size: 1.2rem;
  }
  
  .feedbackIcon:hover {
    color: #000;
  }
.circle-icon .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
}

#chat-header-preview {
    padding: 12px;
    display: flex; 
    align-items: center;
    justify-content: center; 
    border-radius: 15px 15px 0 0;
    font-weight: 600;
    position: relative;
}

#chat-header-preview .header-content-preview {
    flex-grow: 1; 
    text-align: center; 
}

#chat-header-preview .header-content-preview span {
    display: inline-block; 
    margin-right: 1.5rem;
}

#chat-header-preview .minimise-icon-preview {
    position: absolute;
    right: 12px; 
    top: 50%; 
    transform: translateY(-50%); 
    cursor: pointer;

}


#chat-body-preview {
    height: 21rem;
    overflow-y: auto;
    padding: 0.8rem;
}
.suggestions-header-preview {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0.5rem;
}

.close-icon-preview {
    cursor: pointer;
    font-size: 1rem;
    color: #555;
}

.close-icon-preview:hover {
    color: #000;
}

#suggested-questions-preview {
    padding: 0.5rem;
    border-top: 1px solid #e0e0e0;
    /* background-color: #f9f9f9; */
    overflow-y: auto;
    white-space: nowrap;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.suggestions-container-preview {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.suggestion-button-preview {
    padding: 8px 12px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    flex-shrink: 0; /* Prevent buttons from shrinking */
}

.suggestion-button-preview:hover {
    background-color: #e0e0e0; /* Change colour on hover */
}



.message {
    display: block;
    word-wrap: break-word;
    margin: 0.5rem 0;
    padding: 0.2rem 0.6rem;
    border-radius: 18px;
    position: relative;
    max-width:85%;
    clear: both;
    overflow: auto;

}

.message.user {
    text-align: left;
    border-bottom-right-radius: 0;
    float: right;
}

.message.assistant {
    text-align: left;
    border-bottom-left-radius: 0;
    margin-left: 10px;
}

.chatFooterMinimised {
    position: fixed;
    margin-right: 15px;
    align-items: center;
    background-color: white;
    border-radius: 15px;

    width: 25%;
    display: flex
;
  }

#chat-footer {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
}

#chat-input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 20px;
    outline: none;
    resize: none; 
    overflow: hidden; 
    min-height: 1.1rem; /* Minimum height */
    max-height: 100px; 
}

#send-button {
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;

}



#send-button:disabled {
    background-color: #A9BCCB;
    color: #f5f5f5;
}

.bot-icon {
    width: 2.8rem;
    height: 2.6rem;
    border-radius: 50%;
    float: left;
    margin-right: 5px;
}
.typing-animation {
    display: inline-block;
    vertical-align: middle; 
}

.typing-animation span {
    display: inline-block;
    background-color: black; 
    width: 0.5rem; 
    height: 0.5rem; 
    border-radius: 50%;
    margin: 0 0.1rem; 
    animation: blink 1.4s infinite ease-in-out;
}

@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.typing-animation span:nth-child(1) {
    animation-delay: 0s;
}

.typing-animation span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-animation span:nth-child(3) {
    animation-delay: 0.4s;
}

@media only screen and (max-width: 768px) {
    
    @keyframes textColorChange {
        0% {color:white}
        50% {color: black}
        100% {color: white}
    }
    
    #chat-widget {
        width: 100%;
    }
    .text-color-change {
        animation: textColorChange 4s infinite;
    }
}


.lead-capture-popup-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002; /* Ensure it's above other elements */
}

.popup-content-preview {
    background-color: white;
    padding: 0.8rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 90%;
}



.popup-content-preview input {
    display: block;
    width: 100%;
    padding: 0.4rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}


.minimizedContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10001;

  }
  
  
  .callMessage {
    background-color: #fff;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  
  .callMessageContainer {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin-right: 2px;
  }
  

  
  .callMessageContainer {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin-right: 2px;
  }
  
  .callMessage {
    font-size: 14px;
    color: #000;
  }
  
  .closeCallMessage {
    font-size: 14px;
    color: #999;
    margin-left: 8px;
    cursor: pointer;
  }
  
  .closeCallMessage:hover {
    color: #000;
  }

  