@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --navbar-height: 3rem; 
}
.navbar {
  background-color: #092F36;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 10rem;
  font-family: 'Poppins', sans-serif;
  position: fixed; 
  top: 0;       
  left: 0;        
  right: 0;      
  z-index: 100;  
  height: var(--navbar-height);

}

.nav-brand {
  background-color: rgb(9, 47, 54);
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 0;
}
.nav-brand-logo {
  background-color: rgb(9, 47, 54);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-image {
  width: 4.5rem;
  margin: 0;
}

.nav-brand:hover, .nav-brand:visited {
  color: white;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.hamburger {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  display: none;
  cursor: pointer;
  margin-left: auto;
}

.nav-items {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-items li {
  margin-left: 4rem;
}

.nav-items li a {
  display: block; 

  color: white;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 600;
}

.nav-items li a:hover {
  text-decoration: underline;
}

.nav-items.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 3rem;
  }

  .hamburger {
    display: block;
  }

  .nav-items, .nav-items.expanded {
    position: absolute; 
    top: 100%; 
    right: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #092F36;
    z-index: 1000; 
  }

  .nav-items.expanded {
    display: flex;
    border-top:1px solid white;

  }

  .nav-brand, .nav-menu {
    width: 100%;
    justify-content: space-between;
  }

  .nav-items li {
    margin: 10px 0;
    width: 100%; 
    padding: 10px 20px;
  }
}
