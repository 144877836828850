body {
  font-family: 'Poppins', sans-serif;
  background-color: #f5fdff;
}

.login-container {
  max-width: 500px; 
  margin: 7rem auto;
  padding: 20px;
  background-color: white; 
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #005161; 
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input-group {
  margin-bottom: 20px;
}
.password-group {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #aaa;
  user-select: none; /* Prevent text selection */
}

.password-toggle.show {
  color: #005161; /* Change colour when password is visible */
}

.login-input {
  width: 100%;
  padding: 0.5rem 0;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Poppins";
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
  outline-color: #005161;
}

.login-input:focus {
  border-color: #005161; 
}

.login-button {
  padding: 12px;
  background-color: #005161;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.login-button:hover {
  background-color: #0c687a;
}

.login-error {
  padding: 10px;
  border-radius: 4px;
  background-color: #f8d7da; 
  color: #721c24;
  text-align: center;
}

.login-success {
  padding: 10px;
  border-radius: 4px;
  background-color: #d4edda; 
  color: #155724;
  text-align: center;
}

.login-footer {
  text-align: center;
  margin-top: 20px;
}

.login-footer a {
  color: #005161;
  text-decoration: none;
}

.login-footer a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .login-container {
    margin: 5rem auto;
  }
}
