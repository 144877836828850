.spinner {
  background-color: rgba(211, 211, 211, 0.549);
  height: 100vh;
  background-color: rgba(211, 211, 211, 0.549);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100vw;
  position: fixed;
  top:0;
  left:0;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
