.knowledge-base-tab-container {
  display: flex;
  flex-direction: column;
}
/*.sources {
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  height: 100%; 
} */
.knowledge-base-tab {
  flex: 1;
  margin:0 1rem 0 0;
}
.file-types {
  visibility: hidden;
}
.sources .MuiPaper-root {
  flex-grow: 1; /* Make the Paper element grow */
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the Paper element fills the available height */
}

.sources .TableVirtuoso {
  flex-grow: 1; /* Make the TableVirtuoso element grow */
  height: 100%; /* Ensure the TableVirtuoso element fills the available height */
}
.chatbot-info-kb {
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.qas-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-messages-tab {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

.qa-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.qa-field input,
.qa-field textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Poppins";
}

.save-qa-button,
.add-qa-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.save-qa-button:hover,
.add-qa-button:hover {
  background-color: #218838;
}

.sources-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1rem;
}

.error {
  color: red;
  margin-top: 0.5rem;
}

.search-bar {
  display: flex;
  gap: 1rem;
  margin: 10px 0;
}

.search-bar input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  width: 30rem;

}

.search-button,
.clear-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover,
.clear-button:hover {
  background-color: #0056b3;
}

.file-drop-zone p {
  margin: 0;
}

.file-upload-input {
  display: none;
}

.file-upload-input::file-selector-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-input::file-selector-button:hover {
  background-color: #0056b3;
}

.save-text-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.save-text-button:hover {
  background-color: #0056b3;
}

.url-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.url-input {
  padding: 10px;
  width: 300px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.fetch-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fetch-button:hover {
  background-color: #0056b3;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: #005161;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0c687a;
}

.custom-fileUploader {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 80%;
  height: 48px;
  border: 2px dashed  #092F36 !important; 
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 100% !important;
  flex-direction: column;

}
.file-upload-container{
  width: 80%;
}

.table-container {
  width: 100%;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
  text-align: left;
}


/* Media Queries for Mobile Responsiveness */
@media (max-width: 600px) {
  .knowledge-base-tab-container {
    padding: 0.5rem;
  }

  .qas-list {
    flex-direction: column;
  }

  .search-bar {
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-bar input {
    width: 100%;
  }

  .search-button,
  .clear-button {
    width: 100%;
  }

  .url-field {
    flex-direction: column;
  }


  .fetch-button {
    width: 100%;
  }

  .custom-fileUploader {
    min-width: unset !important;
  }

  .table th, .table td {
    padding: 6px;
    font-size: 0.875rem;
  }

  .sources-content {
    margin: 0.5rem;
  }
  .file-upload-container{
    width: 100%;
  }
}

