body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5fdff;
  }
  
  .reset-password-container {
    max-width: 500px; 
    margin: 7rem auto;
    padding: 20px;
    background-color: white; 
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #005161; 
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-input-group {
    margin-bottom: 20px;
  }
  .password-group {
    position: relative;
  }
  .reset-password-input {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border: 2px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out;
    outline-color: #005161;
  }
  
  .reset-password-input:focus {
    border-color: #005161; 
  }
  
  .reset-password-button {
    padding: 12px;
    background-color: #005161;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .reset-password-button:hover {
    background-color: #0c687a;
  }
  
  .reset-password-message {
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-top: 20px;
  }
  
  .reset-password-message.success {
    background-color: #d4edda; 
    color: #155724;
  }
  
  .reset-password-message.error {
    background-color: #f8d7da; 
    color: #721c24;
  }
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #aaa;
    user-select: none; /* Prevent text selection */
  }
  
  .password-toggle.show {
    color: #005161; /* Change colour when password is visible */
  }
  
  @media (max-width: 600px) {
    .reset-password-container {
      margin: 5rem auto;
    }
  }
  